<template>
  <div class="container">
    <div class="main">
      <div class="box1">
        <div class="flexSb box1_main">
          <div class="box1_header">
            <div class="box1_left flexSb">
              <div class="item_left">
                <div class="grade">
                  {{ Info.grade_name }}{{ Info.class_name }}
                </div>
                <div>
                  <span>班级:</span> {{ Info.student_count }}人<span>
                    出勤:</span
                  >
                  {{ Info.attendance_count }}人
                </div>
              </div>
              <div>
                <div class="left_list">
                  <span>课程名称：</span>
                  {{ Info.course_typeName }}
                </div>
                <div class="left_list">
                  <span>课程时间：</span>
                  {{ Info.showtime }}
                </div>
                <div class="left_list flexSt">
                  <span>运动项目：</span>
                  <div v-if="Info.project_name" class="flexSt">
                    <div
                      class="sport"
                      v-for="(item, index) in Info.project_name.split(',')"
                      :key="index">
                      {{ item }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flexSb">
              <div class="timerep" ref="timeheart"></div>

              <div class="list_ri flexSt">
                <div
                  :style="{ background: item.bgcolor }"
                  class="litem"
                  v-for="(item, index) in list_type"
                  :key="index">
                  <div class="title">
                    {{ item.title }}
                  </div>

                  <div class="num" :style="{ color: item.color }">
                    {{ item.num ? item.num : "--" }}<span>{{ item.dw }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box1_right">
            <div class="box1_right_title">指标分析</div>
            <div class="table">
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeaderColor"
                :header-cell-class-name="cellClass"
                :cell-class-name="returnName">
                <el-table-column
                  min-width="300"
                  label="运动指标"
                  align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.$index == 0">
                      <div class="flexSb prw_title">
                        <div>平均心率130-160次/分</div>
                        <div>
                          {{ list_type[0].num ? list_type[0].num : "--" }}次/分
                        </div>
                      </div>
                      <div class="flexSb">
                        <div class="pro">
                          <el-progress
                            :show-text="false"
                            :stroke-width="13"
                            :percentage="avg_heart_qualified_hg"
                            :active-color="
                              box1Info.avg_heart_type_num != 1
                                ? '#EC6363'
                                : '#6DD27D'
                            "></el-progress>
                        </div>
                        <div v-if="list_type[0].num < 130" class="pg">
                          <!-- 正常 -->
                          偏低
                        </div>
                        <div
                          v-if="
                            (list_type[0].num > 130 &&
                              list_type[0].num < 160) ||
                            list_type[0].num == 130 ||
                            list_type[0].num == 160
                          "
                          class="zc">
                          正常
                        </div>
                        <div v-if="list_type[0].num > 160" class="pg">
                          <!-- 正常 -->
                          偏高
                        </div>
                      </div>
                    </div>
                    <div v-if="scope.$index == 1">
                      <div class="flexSb prw_title">
                        <div>最高心率130-160次/分</div>
                        <div>
                          {{ list_type[1].num ? list_type[1].num : "--" }}次/分
                        </div>
                      </div>
                      <div class="flexSb">
                        <div class="pro">
                          <el-progress
                            :show-text="false"
                            :stroke-width="13"
                            :active-color="
                              box1Info.max_heart_type_num != 1
                                ? '#EC6363'
                                : '#6DD27D'
                            "
                            :percentage="max_heart_qualified_hg"></el-progress>
                        </div>
                        <div v-if="list_type[1].num < 130" class="pg">
                          <!-- 正常 -->
                          偏低
                        </div>
                        <div
                          v-if="
                            (list_type[1].num > 130 &&
                              list_type[1].num < 160) ||
                            list_type[1].num == 130 ||
                            list_type[1].num == 160
                          "
                          class="zc">
                          正常
                        </div>
                        <div v-if="list_type[1].num > 160" class="pg">
                          <!-- 正常 -->
                          偏高
                        </div>
                      </div>
                    </div>
                    <div v-if="scope.$index == 2">
                      <div class="flexSb prw_title">
                        <div>运动强度50%-60%</div>
                        <div>
                          {{ rightInfo.strength ? rightInfo.strength : "--" }}%
                        </div>
                      </div>
                      <div class="flexSb">
                        <div class="pro">
                          <el-progress
                            :show-text="false"
                            :stroke-width="13"
                            :active-color="
                              box1Info.strength_type_num != 1
                                ? '#EC6363'
                                : '#6DD27D'
                            "
                            :percentage="
                              rightInfo.strength_ratio
                            "></el-progress>
                        </div>
                        <div v-if="rightInfo.strength < 50" class="pg">
                          <!-- 正常 -->
                          偏低
                        </div>
                        <div
                          v-if="
                            (rightInfo.strength > 50 &&
                              rightInfo.strength < 60) ||
                            rightInfo.strength == 50 ||
                            rightInfo.strength == 60
                          "
                          class="zc">
                          正常
                        </div>
                        <div v-if="rightInfo.strength > 60" class="pg">
                          <!-- 正常 -->
                          偏高
                        </div>
                      </div>
                    </div>
                    <div v-if="scope.$index == 3">
                      <div class="flexSb prw_title">
                        <div>运动密度40%-50%</div>
                        <div>
                          {{ rightInfo.density ? rightInfo.density : "--" }}%
                        </div>
                      </div>
                      <div class="flexSb">
                        <div class="pro">
                          <el-progress
                            :show-text="false"
                            :stroke-width="13"
                            :percentage="rightInfo.density_ratio"
                            :active-color="
                              box1Info.density_type_num != 1
                                ? '#EC6363'
                                : '#6DD27D'
                            "></el-progress>
                        </div>
                        <div v-if="rightInfo.density < 40" class="pg">
                          <!-- 正常 -->
                          偏低
                        </div>
                        <div
                          v-if="
                            (rightInfo.density > 40 &&
                              rightInfo.density < 50) ||
                            rightInfo.density == 40 ||
                            rightInfo.density == 50
                          "
                          class="zc">
                          正常
                        </div>
                        <div v-if="rightInfo.density > 50" class="pg">
                          <!-- 正常 -->
                          偏高
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="80" label="合格" align="center">
                  <template slot-scope="scope">
                    <el-popover
                      @show="handleGetShow(scope.$index, 0)"
                      trigger="click"
                      placement="top">
                      <el-table
                        class="table_item"
                        :data="pouplist"
                        style="width: 100%"
                        height="503">
                        <el-table-column
                          prop="student_no"
                          label="班号"
                          width="80">
                        </el-table-column>
                        <el-table-column
                          prop="student_name"
                          label="姓名"
                          width="180"
                          align="center">
                        </el-table-column>
                        <el-table-column
                          prop="student_gender_name"
                          width="80"
                          label="性别"
                          align="center">
                        </el-table-column
                        ><el-table-column
                          prop="avg_heart"
                          width="160"
                          label="平均心率"
                          align="center">
                          <template slot-scope="scope">
                            <div>
                              {{
                                scope.row.avg_heart ? scope.row.avg_heart : "--"
                              }}
                            </div>
                          </template> </el-table-column
                        ><el-table-column
                          prop="address"
                          width="80"
                          label="操作">
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              @click="handleSeeSingle(scope.row)"
                              >查看</el-button
                            >
                          </template>
                        </el-table-column>
                      </el-table>

                      <div slot="reference" class="name-wrapper">
                        <div class="flexSc">
                          <div v-if="scope.$index == 0" class="num">
                            <span>
                              {{
                                avg_heart_qualified ? avg_heart_qualified : "--"
                              }}</span
                            >
                            人
                          </div>
                          <div v-if="scope.$index == 1" class="num">
                            <span>
                              {{
                                max_heart_qualified ? max_heart_qualified : "--"
                              }}</span
                            >
                            人
                          </div>
                          <div v-if="scope.$index == 2" class="num">
                            <span>
                              {{
                                rightInfo.strength_qualified
                                  ? rightInfo.strength_qualified
                                  : "--"
                              }}</span
                            >
                            人
                          </div>
                          <div v-if="scope.$index == 3" class="num">
                            <span>
                              {{
                                rightInfo.density_qualified
                                  ? rightInfo.density_qualified
                                  : "--"
                              }}</span
                            >
                            人
                          </div>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column label="不合格" width="80" align="center">
                  <template slot-scope="scope">
                    <el-popover
                      @show="handleGetShow(scope.$index, 1)"
                      trigger="click"
                      placement="top">
                      <el-table
                        class="table_item"
                        :data="pouplist"
                        style="width: 100%"
                        height="503">
                        <el-table-column
                          prop="student_no"
                          label="班号"
                          width="80">
                        </el-table-column>
                        <el-table-column
                          prop="student_name"
                          label="姓名"
                          width="180"
                          align="center">
                        </el-table-column>
                        <el-table-column
                          prop="student_gender_name"
                          width="80"
                          label="性别"
                          align="center">
                        </el-table-column
                        ><el-table-column
                          prop="avg_heart"
                          width="160"
                          label="平均心率"
                          align="center">
                          <template slot-scope="scope">
                            <div>
                              {{
                                scope.row.avg_heart ? scope.row.avg_heart : "--"
                              }}
                            </div>
                          </template> </el-table-column
                        ><el-table-column
                          prop="address"
                          width="80"
                          label="操作">
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              @click="handleSeeSingle(scope.row)"
                              >查看</el-button
                            >
                          </template>
                        </el-table-column>
                      </el-table>

                      <div slot="reference" class="name-wrapper">
                        <div class="flexSc">
                          <div v-if="scope.$index == 0" class="num">
                            <span>
                              {{
                                box1Info.attendance_um - avg_heart_qualified
                                  ? box1Info.attendance_um - avg_heart_qualified
                                  : "--"
                              }}</span
                            >
                            人
                          </div>
                          <div v-if="scope.$index == 1" class="num">
                            <span>
                              {{
                                box1Info.attendance_um - max_heart_qualified
                                  ? box1Info.attendance_um - max_heart_qualified
                                  : "--"
                              }}</span
                            >
                            人
                          </div>
                          <div v-if="scope.$index == 2" class="num">
                            <span>
                              {{
                                rightInfo.strength_unqualified
                                  ? rightInfo.strength_unqualified
                                  : "--"
                              }}</span
                            >
                            人
                          </div>
                          <div v-if="scope.$index == 3" class="num">
                            <span>
                              {{
                                rightInfo.density_unqualified
                                  ? rightInfo.density_unqualified
                                  : "--"
                              }}</span
                            >
                            人
                          </div>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  width="80"
                  fixed="right"
                  label="达标率"
                  align="center">
                  <template slot-scope="scope">
                    <div class="name-wrapper bb">
                      <div class="flexSc">
                        <div v-if="scope.$index == 0" class="num">
                          <span>
                            {{
                              avg_heart_qualified_hg
                                ? avg_heart_qualified_hg
                                : "--"
                            }}</span
                          >
                          %
                        </div>
                        <div v-if="scope.$index == 1" class="num">
                          <span>
                            {{
                              max_heart_qualified_hg
                                ? max_heart_qualified_hg
                                : "--"
                            }}</span
                          >
                          %
                        </div>
                        <div v-if="scope.$index == 2" class="num">
                          <span>
                            {{
                              rightInfo.strength_ratio
                                ? rightInfo.strength_ratio
                                : "--"
                            }}</span
                          >
                          %
                        </div>
                        <div v-if="scope.$index == 3" class="num">
                          <span>
                            {{
                              rightInfo.density_ratio
                                ? rightInfo.density_ratio
                                : "--"
                            }}</span
                          >
                          %
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>

      <div class="box2">
        <div class="box2_title">平均心率曲线</div>
        <div class="options_a_a">
          <!-- :opts="opts" -->
          <div class="averageheart" ref="averageheart"></div>
        </div>
      </div>

      <div class="box3">
        <div class="box2_title flexSb">
          <div>单个学生数据</div>
          <div class="flexSe aaaaaa">
            <div class="">
              全部（{{ box1Info.class_num ? box1Info.class_num : "--" }}人）
            </div>
            <div class="sqqq">
              出勤（{{
                box1Info.attendance_um ? box1Info.attendance_um : "--"
              }}） 人
            </div>
            <div class="sqqq2">
              未出勤（{{
                box1Info.class_num - box1Info.attendance_um
                  ? box1Info.class_num - box1Info.attendance_um
                  : "--"
              }}人）
            </div>
          </div>
        </div>
        <el-table
          ref="multipleTable"
          :data="scheduleStudentlist"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeaderColor"
          :header-cell-class-name="cellClass"
          :cell-class-name="returnName">
          <el-table-column
            min-width="80"
            label="班号"
            prop="student_no"
            align="center">
          </el-table-column>
          <el-table-column
            prop="student_name"
            min-width="80"
            label="姓名"
            align="center">
          </el-table-column>
          <el-table-column
            label="性别"
            width="80"
            prop="student_gender_name"
            align="center">
          </el-table-column>

          <el-table-column
            prop="avg_heart"
            label=" 平均心率（次/分)"
            width="146"
            align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.avg_heart ? scope.row.avg_heart : "- -" }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="max_heart"
            label="最高心率（次/分)"
            width="146"
            align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.max_heart ? scope.row.max_heart : "- -" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="target_heart"
            label="靶心率运动时间"
            width="146"
            align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.target_heart ? scope.row.target_heart : "- -" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="time"
            label="心率≥120时间"
            width="146"
            align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.time ? scope.row.time : "- -" }}</div>
            </template>
          </el-table-column>

          <el-table-column
            prop="strength"
            label="运动强度"
            min-width="94"
            align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.strength ? scope.row.strength : "- -" }}%</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="density"
            label="运动密度"
            min-width="94"
            align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.density ? scope.row.density : "- -" }}%</div>
            </template>
          </el-table-column>

          <el-table-column label="出勤情况" min-width="94" align="center">
            <template slot-scope="scope">
              <div>
                <el-tag
                  type="success"
                  v-if="scope.row.attendance_status == 1"
                  class="cq">
                  出勤
                </el-tag>
                <el-tag type="danger" v-else class="wcq"> 未出勤 </el-tag>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            min-width="80"
            fixed="right"
            label="操作"
            align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleSeeSingle(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { timeheart, averageheart } from "./enums";
import { getUser } from "@/utils/auth";
import {
  reportSchedule,
  classGraph,
  scheduleStudent,
} from "@/api/classroon/practice.js";
export default {
  data() {
    return {
      scheduleStudentlist: [],
      pouplist: [],
      tableData: [1, 2, 3, 4],
      timeheart: timeheart,
      list_type: [
        {
          title: "平均心率",
          num: 0,
          dw: "次/分",
          color: "#57C365",
          bgcolor: "#F2FCF4",
        },
        {
          title: "平均最高心率",
          num: 0,
          dw: "次/分",
          color: "#F16666",
          bgcolor: "#FEF7F7",
        },
        {
          title: "心率≥120时间",
          num: 0,
          color: "#58B0EB",
          bgcolor: "#F6FAFD",
        },
        {
          title: "运动密度",
          num: 0,
          color: "#EEB126",
          bgcolor: "#FFFAEF",
          dw: "%",
        },
        {
          title: "运动强度",
          num: 0,
          color: "#5AD2BE",
          bgcolor: "#F1FAF9",
          dw: "%",
        },
        {
          title: "平均消耗",
          num: 0,
          dw: "千卡",
          color: "#F8885B",
          bgcolor: "#FDF6F3",
        },
      ],
      averageheart: averageheart,
      box1Info: {
        class_num: 0,
        attendance_um: 0,
      },
      Info: {
        grade_name: "",
        class_name: "",
        student_count: "",
        attendance_count: "",
        course_typeName: "",
        showtime: "",
        project_name: "",
      },

      tableData2: [1],
      avg_heart_qualified: "",
      avg_heart_qualified_hg: 0,
      max_heart_qualified: "",
      max_heart_qualified_hg: 0,
      rightInfo: {
        density: "",
        density_qualified: "",
        density_unqualified: "",
        density_ratio: 0,
        density_type: "",
        strength: "",
        strength_qualified: "",
        strength_unqualified: "",
        strength_ratio: 0,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {});
    if (this.$route.query.val) {
      this.teacher_id = getUser().id;
      this.Info = JSON.parse(this.$route.query.val);
      let val = this.Info;
      console.log(val);
      this.Info.showtime = val.date + val.time;
      this.Info.course_typeName =
        val.course_type == 1 ? "常规体育课" : "大课间";
      this.handleGetreportSchedule();
      this.handleGetscheduleStudent();
      this.handleclassGraph();
    }
  },
  methods: {
    async handleGetscheduleStudent() {
      let data = {
        id: this.Info.id,
        class_id: this.Info.class_id,
        per_page: 99999,
        page: 1,
      };
      await scheduleStudent(data).then((res) => {
        this.scheduleStudentlist = res.data.data;
      });
    },
    async handleclassGraph() {
      let data = {
        id: this.Info.id,
      };
      await classGraph(data).then((res) => {
        let time = res.data.time;
        let boys = res.data.boys;
        let class_list = res.data.class_data;
        let girls = res.data.girls;
        this.averageheart.xAxis.data = time;
        this.averageheart.series[0].data = class_list;
        this.averageheart.series[1].data = boys;
        this.averageheart.series[2].data = girls;

        this.handleInitaverageheart();
      });
    },
    // 合格跟不合格人数列表
    async handleGetscheduleStudentpouplist(pouptype) {
      // （1 = 平均心率合格，2 = 平均心率不合格
      // 3 = 最高心率合格，4 = 最高心率不合格
      // 5 = 运动强度合格，6 = 运动强度不合格
      // 7 = 运动密度合格，8 = 运动密度不合格）
      this.loading = true;
      this.pouplist = [];
      let data = {
        id: this.Info.id,
        class_id: this.Info.class_id,
        per_page: 99999,
        page: 1,
        type: pouptype,
      };
      await scheduleStudent(data).then((res) => {
        this.pouplist = res.data.data;
        this.loading = false;
        this.totalPoup = res.data.total;
      });
    },
    async handleGetreportSchedule() {
      let data = {
        class_id: this.Info.class_id,
        id: this.Info.id,
      };
      await reportSchedule(data).then((res) => {
        let val = res.data;

        if (val) {
          this.box1Info = res.data;
          this.list_type[0].num = val.avg_heart;
          this.list_type[1].num = val.max_heart;
          this.list_type[2].num = val.heart_than_120;
          this.list_type[3].num = val.density;
          this.list_type[4].num = val.strength;
          console.log(res, "==reportSchedule");

          this.target_heart = val.target_heart;
          this.timeheart.series[0].data[0].name = val.target_heart + "分钟";
          this.handleInittimeheart();

          this.avg_heart_qualified = val.avg_heart_qualified;
          this.max_heart_qualified = val.max_heart_qualified;
          this.avg_heart_qualified_hg =
            (this.avg_heart_qualified / this.box1Info.attendance_um) * 100;
          this.max_heart_qualified_hg =
            (this.avg_heart_qualified / this.box1Info.attendance_um) * 100;

          this.rightInfo = {
            density: val.density,
            density_qualified: val.density_qualified,
            density_unqualified: val.density_unqualified,
            density_ratio: val.density_ratio,
            density_type: val.density_type,
            strength: val.strength,
            strength_qualified: val.strength_qualified,
            strength_unqualified: val.strength_unqualified,
            strength_ratio: val.strength_ratio,
          };
        } else {
          // this.getServerData1(0, 0);
        }
      });
    },
    handleGetShow(index, type) {
      console.log(222, index, type);
      switch (index) {
        case 0:
          if (type == 0) {
            this.handleGetscheduleStudentpouplist(1);
          } else {
            this.handleGetscheduleStudentpouplist(2);
          }

          break;

        case 1:
          if (type == 0) {
            this.handleGetscheduleStudentpouplist(3);
          } else {
            this.handleGetscheduleStudentpouplist(4);
          }

          break;
        case 2:
          if (type == 0) {
            this.handleGetscheduleStudentpouplist(5);
          } else {
            this.handleGetscheduleStudentpouplist(6);
          }

          break;
        case 3:
          if (type == 0) {
            this.handleGetscheduleStudentpouplist(7);
          } else {
            this.handleGetscheduleStudentpouplist(8);
          }

          break;
      }
    },
    handleSeeSingle(val) {
      this.$router.push(
        "/singleclassreport?student=" +
          JSON.stringify(val) +
          "&val=" +
          JSON.stringify(this.Info)
      );
      console.log(111);
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: "13px",
        color: "rgb(0,12,22)",
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "#F2F7FF",
      };
    },
    cellClass() {
      return "disableSelection";
    },
    returnName(obj) {
      if (obj.columnIndex == 0) {
        return "firstCell";
      }
    },

    handleInitaverageheart() {
      let chartDom = this.$refs.averageheart;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.averageheart);
    },
    handleInittimeheart() {
      let chartDom = this.$refs.timeheart;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.timeheart);
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
}
.main {
  flex: 1;
  .box1 {
    width: 100%;
    border-radius: 20px;
    background-color: #fff;
    .box1_main {
      width: 100%;
      align-items: stretch;
    }
    .box1_header {
      align-items: stretch;
      width: 50%;
      .box1_left {
        padding: 16px 27px 13px 55px;
        box-sizing: border-box;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(230, 230, 230, 1);
        .grade {
          color: rgba(16, 16, 16, 1);
          font-size: 28px;
        }
        .item_left {
          color: rgba(115, 115, 115, 1);
          font-size: 16px;
        }
        .left_list {
          margin-bottom: 6px;
          color: rgba(16, 16, 16, 1);
          font-size: 16px;
          span {
            font-weight: 600;
          }
          .sport {
            height: 23px;
            line-height: 23px;
            border-radius: 5px;
            background-color: rgba(255, 255, 255, 1);
            color: rgba(146, 146, 146, 1);
            font-size: 14px;
            text-align: center;
            font-family: Roboto;
            padding: 0 5px;
            box-sizing: border-box;
            border: 1px solid rgba(146, 146, 146, 1);
            margin-right: 6px;
          }
        }
      }
      .timerep {
        width: 127px;
        height: 119px;
      }
      .list_ri {
        margin-top: 18px;
        flex: 1;
        flex-wrap: wrap;
        .litem {
          width: 31%;
          height: 70px;
          border-radius: 10px;
          margin-bottom: 5px;
          margin-left: 5px;
          text-align: center;
          padding-top: 12px;
          box-sizing: border-box;

          .title {
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #444444;
          }

          .num {
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;

            span {
              font-size: 16px;
            }
          }
        }
      }
    }
    .box1_right {
      flex: 1;
      padding: 16px 0px 11px 13px;
      border-left: 1px solid rgba(230, 230, 230, 1);
      border-bottom: 1px solid rgba(230, 230, 230, 1);
      box-sizing: border-box;
      .box1_right_title {
        color: rgba(16, 16, 16, 1);
        font-size: 20px;
      }
      .table {
        margin-top: 9px;
        .prw_title {
          width: 91%;
          color: rgba(94, 94, 94, 1);
          font-size: 14px;
        }
        .pro {
          width: 91%;
        }
        .num {
          margin-top: 18px;
          cursor: pointer;
          span {
            text-decoration: underline;
          }
        }
        .bb {
          .num {
            cursor: default;
            span {
              text-decoration: none;
            }
          }
        }
        /deep/ .el-table td.el-table__cell,
        /deep/.el-table th.el-table__cell.is-leaf {
          border-bottom: 0;
        }
      }
      /deep/.el-table::before {
        height: 0;
      }
      /deep/.el-table .el-table__cell {
        padding: 0;
      }
    }
  }
  .box2 {
    padding: 13px 28px;
    box-sizing: border-box;
    height: 320px;
    line-height: 20px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    margin: 11px 0;
    color: rgba(16, 16, 16, 1);
    font-size: 20px;
    font-weight: 600;
    .averageheart {
      width: 100%;
      height: 280px;
    }
  }
  .box3 {
    padding: 13px 28px;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 1);
    .box2_title {
      color: rgba(16, 16, 16, 1);
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .aaaaaa {
      font-size: 16px;
    }
  }
}
.zc {
  color: #4cda63;
}

.pg {
  color: #ff3636;
}
</style>
